import { createRouter, createWebHashHistory } from 'vue-router';



const routes = [

    { path: '/login', component: () => import('@/Views/Login.vue') },
    { path: '/', component: () => import('@/Views/Home.vue') },
    { path: '/Home', component: () => import('@/Views/Home.vue') },
    { path: '/ServiceActions', component: () => import('@/Views/ServiceActions/serviceActions.vue') },
    { path: '/ServiceActions/Add/:customerId', component: () => import('@/Views/ServiceActions/serviceActionsAdd.vue') },
    { path: '/ServiceActions/Edit/:id', component: () => import('@/Views/ServiceActions/serviceActionsEdit.vue') },
    { path: '/Customers', component: () => import('@/Views/Customer/CustomerSearchView.vue') },
    { path: '/Customers/Add', component: () => import('@/Views/Customer/CustomerAddView.vue') },
    { path: '/Customers/Edit/:id', component: () => import('@/Views/Customer/CustomerEditView.vue') },    

]

const router = createRouter({
    history: createWebHashHistory(),
    routes: routes
});

export default router;