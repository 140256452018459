

class SecurityService {

    _jwtToken = ""
    _isInitialized = false;

    constructor() {
        if (!SecurityService.instance) {
            SecurityService.instance = this;
        }

        return SecurityService.instance;
    }

    GetTokenData() {

        if (this._isInitialized)
        {
            return this._jwtToken;
        }
            
        else
        {
            var tokenFromLocalStorage = localStorage.getItem('token');

            if (tokenFromLocalStorage != null) {


                this._jwtToken = tokenFromLocalStorage;


                this._isInitialized = true;

                return this._jwtToken;
            }
        }

        return '';
    }

    logOut() {
        this._jwtToken = '';
        this._isInitialized = false;

        localStorage.setItem('token', this._jwtToken);
    }

    GetCompanyCode() {
        return this._companyCode;
    }

    Initialize(token) {
        this._jwtToken = token;
        localStorage.setItem('token', this._jwtToken);

        this._isInitialized = true;
    }

    IsInitialized() {

        //console.log("IsInitialized: " + this._isInitialized);
        //this._isInitialized = true;
        //this._jwtToken = "temp";

        if (!this._isInitialized) {
            this.GetTokenData();
        }


        return this._isInitialized && this._jwtToken !== "";
    }
}

export default SecurityService;