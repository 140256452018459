<template>
        <div class="container">

            <nav-component ref="navComponent"></nav-component>

            <div class="content">
                <top-nav-component ref="topNavComponent"></top-nav-component>
                <router-view></router-view>
                <footer class="footer">
                    <div class="row g-0 justify-content-between fs--1 mt-4 mb-3">
                        <div class="col-12 col-sm-auto text-center">
                            <p class="mb-0 text-600">Footer<span class="d-none d-sm-inline-block">| </span><br class="d-sm-none" /> 2024 &copy; <a>Serwisowy alarm</a></p>
                        </div>
                    </div>
                </footer>
            </div>

           
        </div>
    <!--<div class="modal fade" id="error-modal" role="dialog">
        <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 500px">
            <div class="modal-content position-relative">
                <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
                    <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body p-0">
                    <div class="rounded-top-lg py-3 ps-4 pe-6 bg-light">
                        <h4 class="mb-1" id="modalExampleDemoLabel">Ups, wystąpił następujący problem:</h4>
                        <h4 class="mt-5 text-danger" id="modalExampleDemoLabel">{{errorMessage}}</h4>

                    </div>
                </div>
                <div class="modal-footer">
                    <button class="btn btn-secondary" type="button" data-bs-dismiss="modal">Zamknij</button>
                </div>
            </div>
        </div>
    </div>-->
</template>


<style lang="scss">
    @import "@/css/theme.scss";
    @import "node_modules/bootstrap/scss/bootstrap.scss";
</style>
<script>
    import TopNavComponent from "@/components/TopNavComponent.vue"
    import navComponent from "@/components/NavComponent.vue"
    import SecurityService from '@/js/securityService.js'
    //import { mapMutations } from 'vuex';
    import store from '@/store';


    export default {
        name: 'App',
        store,
        components: {
            TopNavComponent,
            navComponent
        },

        data() {
            return {
                account: undefined,
                errorMessage: '',
            }
        },
        created: function () {



            function isScriptAlreadyIncluded(src) {
                var scripts = document.getElementsByTagName('script');
                for (var i = 0; i < scripts.length; i++) {
                    if (scripts[i].getAttribute('src') === src) {
                        return true;
                    }
                }
                return false;
            }


            var scriptsPaths = [
                'vendors/popper/popper.min.js',
                'vendors/bootstrap/bootstrap.min.js',
                'vendors/anchorjs/anchor.min.js',
                'vendors/is/is.min.js',
                'vendors/echarts/echarts.min.js',
                'vendors/fullcalendar/main.min.js',
                'assets/js/flatpickr.js',
                'vendors/dayjs/dayjs.min.js',
                'vendors/fontawesome/all.min.js',
                'vendors/lodash/lodash.min.js',
                'https://polyfill.io/v3/polyfill.min.js?features=window.scroll',
                'vendors/list.js/list.min.js',
                'assets/js/theme.js',
                'vendors/simplebar/simplebar.min.js',
                'assets/js/config.js',



            ];

            for (var i = 0; i < scriptsPaths.length; i++) {

                var scriptPath = scriptsPaths[i];

                if (!isScriptAlreadyIncluded(scriptPath)) {
                    const script = document.createElement('script');
                    script.src = scriptPath;
                    script.async = false;
                    document.body.appendChild(script);
                }

            }

            const securityService = new SecurityService();

            if (!securityService.IsInitialized())
                this.$router.push({ path: "/login" });
        },

        methods: {
        }
    }
</script>
