<template>    
    <nav class="navbar navbar-light navbar-vertical navbar-expand-xl navbar-card" v-show="isLogged">
        <div class="d-flex align-items-center">
            <router-link class="navbar-brand"
                         to="/home">

                <div class="d-flex align-items-center py-3">
                    <span class="font-sans-serif">Serwisowo</span>
                </div>
            </router-link>
        </div>
        <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
            <div class="navbar-vertical-content scrollbar">
                <ul class="navbar-nav flex-column mb-3" id="navbarVerticalNav">
                    <li class="nav-item">
                        <!-- parent pages-->
                        <router-link class="nav-link dropdown-indicator" to="/ServiceActions"  role="button" aria-expanded="true">
                                <div class="d-flex align-items-center">
                                    <span class="nav-link-icon"><span class="far fa-clipboard"></span></span><span class="nav-link-text ps-1">Serwisy</span>
                                </div>
                        </router-link>                        
                    </li>
                    <li class="nav-item">
                        <!-- parent pages-->
                        <router-link class="nav-link dropdown-indicator" href="#users" role="button" aria-expanded="true"  to="/Customers">
                            <div class="d-flex align-items-center">
                                <span class="nav-link-icon"><span class="fas fa-users"></span></span><span class="nav-link-text ps-1">Klienci</span>
                            </div>
                        </router-link>
                        <ul class="nav false" id="customers">
                            <li class="nav-item">
                                <router-link class="nav-link"
                                             to="/customers/add">
                                    <div class="d-flex align-items-center">
                                        <span class="nav-link-text ps-1">Dodaj klienta</span>
                                    </div>
                                </router-link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
</template>

<script>
  //  import { useRoute } from 'vue-router';

    export default {
        name: 'navComponent',
        data() {
            return {
                isLogged: true
            }
        },
    }
</script>